import React, { useState } from 'react';
import { FaTimes } from "react-icons/fa";
import './CategoryForm.css'; // Добавляем импорт стилей

const CategoryForm = ({
    channels,
    ...props
}) => {
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};

        if (!props.selectedCategories.length && !props.editItem) {
            newErrors.category = 'Пожалуйста, выберите хотя бы одну категорию товаров';
        }
        if (!props.formData.telegramId) {
            newErrors.telegramId = 'Укажите ID Telegram-канала для публикации';
        }
        if (!props.formData.minDiscount && props.formData.minDiscount !== 0) {
            newErrors.minDiscount = 'Укажите минимальный процент скидки';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        props.onSubmit(e);
    };

    // Verify channels is an array before mapping
    const channelOptions = Array.isArray(channels) ? channels.map(channel => (
        <option key={channel.id} value={channel.telegram_id}>
            {channel.name}
        </option>
    )) : null;

    return (
        <div className="col-12 col-lg-4">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title mb-4">
                        {props.editItem ? 'Изменение настроек категории' : 'Добавление новой категории'}
                    </h5>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">
                                {props.editItem ? 'Категория' : 'Поиск и выбор категории товаров'} <span className="text-danger">*</span>
                            </label>
                            {props.editItem ? (
                                <input
                                    type="text"
                                    className="form-control"
                                    value={props.selectedCategories[0]?.full_path || ''}
                                    disabled
                                />
                            ) : (
                                <div className="category-search-wrapper">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={props.searchQuery}
                                        onChange={(e) => props.onSearchQueryChange(e.target.value)}
                                        placeholder="Начните вводить название категории"
                                    />
                                    {props.isSearching && <div className="text-muted">Идёт поиск категорий...</div>}
                                    {props.searchResults.length > 0 && (
                                        <div className="search-results">
                                            {props.searchResults.map(category => (
                                                <div
                                                    key={category.id}
                                                    className="search-result-item"
                                                    onClick={() => props.onCategorySelect(category)}
                                                >
                                                    {category.full_path}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                            {errors.category && (
                                <div className="text-danger small mt-1">{errors.category}</div>
                            )}
                        </div>

                        {!props.editItem && props.selectedCategories.length > 0 && (
                            <div className="mb-3">
                                <label className="form-label">Выбранные категории:</label>
                                <div className="selected-categories">
                                    {props.selectedCategories.map(category => (
                                        <div key={category.id} className="selected-category">
                                            <span>{category.full_path}</span>
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-danger"
                                                onClick={() => props.onRemoveCategory(category.id)}
                                            >
                                                <FaTimes />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <div className="mb-3">
                            <label className="form-label">
                                Минимальная скидка (в процентах) <span className="text-danger">*</span>
                            </label>
                            <input
                                type="number"
                                className={`form-control ${errors.minDiscount ? 'is-invalid' : ''}`}
                                id="minDiscount"
                                value={props.formData.minDiscount}
                                onChange={props.onInputChange}
                                placeholder="Введите минимальную скидку"
                            />
                            {errors.minDiscount && (
                                <div className="invalid-feedback">{errors.minDiscount}</div>
                            )}
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Минимальная цена товара (в рублях)</label>
                            <input
                                type="number"
                                className="form-control"
                                id="minPrice"
                                value={props.formData.minPrice}
                                onChange={props.onInputChange}
                                placeholder="Введите минимальную цену"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Максимальная цена товара (в рублях)</label>
                            <input
                                type="number"
                                className="form-control"
                                id="maxPrice"
                                value={props.formData.maxPrice}
                                onChange={props.onInputChange}
                                placeholder="Введите максимальную цену"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Минимальный рейтинг товара (от 1 до 5)</label>
                            <input
                                type="number"
                                className="form-control"
                                id="minRating"
                                value={props.formData.minRating}
                                onChange={props.onInputChange}
                                placeholder="Введите минимальную оценку"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Количество страниц для сканирования</label>
                            <input
                                type="number"
                                className="form-control"
                                id="pageRange"
                                value={props.formData.pageRange}
                                onChange={props.onInputChange}
                                placeholder="Введите диапазон страниц"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Бренды, которые нужно исключить</label>
                            <input
                                type="text"
                                className="form-control"
                                id="excludedBrands"
                                value={props.formData.excludedBrands}
                                onChange={props.onInputChange}
                                placeholder="Введите названия брендов через запятую"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Ключевые слова для исключения товаров</label>
                            <input
                                type="text"
                                className="form-control"
                                id="excludedProductNames"
                                value={props.formData.excludedProductNames}
                                onChange={props.onInputChange}
                                placeholder="Введите слова для фильтрации через запятую"
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">
                                Telegram-канал для публикации <span className="text-danger">*</span>
                            </label>
                            <div className="d-flex gap-2">
                                <select
                                    className="form-select"
                                    onChange={(e) => {
                                        if (e.target.value === 'custom') {
                                            return; // Пропускаем, если выбран ручной ввод
                                        }
                                        props.onInputChange({
                                            target: {
                                                id: 'telegramId',
                                                value: e.target.value
                                            }
                                        });
                                    }}
                                    value={props.formData.telegramId || 'custom'}
                                >
                                    <option value="custom">Указать ID вручную</option>
                                    {channelOptions}
                                </select>
                                {(props.formData.telegramId === 'custom' || !props.formData.telegramId) && (
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="telegramId"
                                        value={props.formData.telegramId === 'custom' ? '' : props.formData.telegramId}
                                        onChange={props.onInputChange}
                                        placeholder="Введите Telegram ID"
                                    />
                                )}
                            </div>
                            {errors.telegramId && (
                                <div className="text-danger small mt-1">{errors.telegramId}</div>
                            )}
                        </div>
                        <div className="form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isActive"
                                checked={props.formData.isActive}
                                onChange={props.onInputChange}
                            />
                            <label className="form-check-label">Включить отслеживание категории</label>
                        </div>
                        <div className="text-muted small mb-3">
                            <span className="text-danger">*</span> - обязательные поля для заполнения
                        </div>
                        <div className="d-flex gap-2">
                            <button type="submit" className="btn btn-primary">
                                {props.editItem ? 'Сохранить изменения' : 'Добавить категорию'}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    props.onClear();
                                    setErrors({});
                                }}
                            >
                                {props.editItem ? 'Отменить' : 'Очистить форму'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CategoryForm;