import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
    IconSettings,
    IconAccessPoint,
    IconChevronLeft,
    IconChevronRight,
    IconAlignBoxLeftMiddle,
    IconTicket,
    IconCreditCard,
    IconFileText,
    IconDashboard // Добавляем импорт иконки дашборда
} from "@tabler/icons-react";
import "./Sidebar.css";

const Sidebar = ({ onCollapseChange }) => {
    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapse = () => {
        const newCollapsedState = !collapsed;
        setCollapsed(newCollapsedState);
        onCollapseChange(newCollapsedState); // Уведомляем родителя о смене состояния
    };

    return (
        <div className={`sidebar bg-dark text-light ${collapsed ? "collapsed" : ""}`}>
            <div className="d-flex align-items-center p-3">
                {!collapsed && <h5 className="mb-0">Панель управления</h5>}
                <button className="btn btn-link text-light ms-auto p-0" onClick={toggleCollapse}>
                    {collapsed ? <IconChevronRight size={18} /> : <IconChevronLeft size={18} />}
                </button>
            </div>

            <nav className="nav flex-column">
                <NavLink to="/" className="nav-link text-light">
                    <div className="d-flex align-items-center">
                        <IconDashboard size={20} />
                        {!collapsed && <span className="ms-2">Дашборд</span>}
                    </div>
                </NavLink>
                <NavLink to="/category" className="nav-link text-light">
                    <div className="d-flex align-items-center">
                        <IconAlignBoxLeftMiddle size={20} />
                        {!collapsed && <span className="ms-2">Категории</span>}
                    </div>
                </NavLink>
                <NavLink to="/broadcast" className="nav-link text-light">
                    <div className="d-flex align-items-center">
                        <IconAccessPoint size={20} />
                        {!collapsed && <span className="ms-2">Рассылки</span>}
                    </div>
                </NavLink>
                <NavLink to="/channels" className="nav-link text-light">
                    <div className="d-flex align-items-center">
                        <IconSettings size={20} />
                        {!collapsed && <span className="ms-2">Каналы</span>}
                    </div>
                </NavLink>
                <NavLink to="/promo-codes" className="nav-link text-light">
                    <div className="d-flex align-items-center">
                        <IconTicket size={20} />
                        {!collapsed && <span className="ms-2">Промокоды</span>}
                    </div>
                </NavLink>
                <NavLink to="/sub-plans" className="nav-link text-light">
                    <div className="d-flex align-items-center">
                        <IconCreditCard size={20} />
                        {!collapsed && <span className="ms-2">Планы подписки</span>}
                    </div>
                </NavLink>
                <NavLink to="/texts" className="nav-link text-light">
                    <div className="d-flex align-items-center">
                        <IconFileText size={20} />
                        {!collapsed && <span className="ms-2">Тексты</span>}
                    </div>
                </NavLink>
                <NavLink to="/settings" className="nav-link text-light">
                    <div className="d-flex align-items-center">
                        <IconSettings size={20} />
                        {!collapsed && <span className="ms-2">Настройки</span>}
                    </div>
                </NavLink>
            </nav>
        </div>
    );
};

export default Sidebar;
