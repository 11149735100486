import React, { useState, useEffect, useCallback } from "react";
import TextForm from './TextForm';
import TextTable from './TextTable';
import { textService } from '../config/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TextsComponent = () => {
    const [textsData, setTextsData] = useState({
        items: [],
        total: 0,
        page: 1,
        pages: 1,
        per_page: 10
    });
    const [editItem, setEditItem] = useState(null);
    const [formData, setFormData] = useState({
        key: "",
        content: "",
    });

    const handlePageChange = useCallback(async (page) => {
        try {
            const response = await textService.getTexts(page, textsData.per_page);
            if (!response.ok) throw new Error('Failed to fetch texts');
            const data = await response.json();
            setTextsData(prevData => ({
                ...prevData,
                items: data.items,
                page: page,
                total: data.total,
                pages: data.pages
            }));
        } catch (error) {
            toast.error('Error fetching texts');
        }
    }, [textsData.per_page]);

    useEffect(() => {
        handlePageChange(1);
    }, [handlePageChange]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = editItem
                ? await textService.updateText(editItem.id, formData)
                : await textService.createText(formData);

            if (!response.ok) throw new Error('Failed to save text');

            const savedText = await response.json();

            if (editItem) {
                setTextsData(prevData => ({
                    ...prevData,
                    items: prevData.items.map(text =>
                        text.id === editItem.id ? savedText : text
                    )
                }));
                toast.success('Text updated successfully');
            } else {
                setTextsData(prevData => ({
                    ...prevData,
                    items: [...prevData.items, savedText],
                    total: prevData.total + 1
                }));
                toast.success('Text added successfully');
            }

            setFormData({ key: "", content: "" });
            setEditItem(null);

        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleEdit = (item) => {
        setFormData({
            key: item.key,
            content: item.content
        });
        setEditItem(item);
    };

    const handleDelete = async (id) => {
        try {
            const response = await textService.deleteText(id);
            if (!response.ok) throw new Error('Failed to delete text');

            setTextsData(prevData => ({
                ...prevData,
                items: prevData.items.filter(text => text.id !== id),
                total: prevData.total - 1
            }));
            toast.success('Text deleted successfully');
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <TextTable
                    texts={textsData.items}
                    totalItems={textsData.total}
                    currentPage={textsData.page}
                    totalPages={textsData.pages}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onPageChange={handlePageChange}
                    pageSize={textsData.per_page}
                />
            </div>
            <div className="row mt-4">
                <TextForm
                    formData={formData}
                    editItem={editItem}
                    onInputChange={handleInputChange}
                    onSubmit={handleSubmit}
                    onClear={() => {
                        setFormData({ key: "", content: "" });
                        setEditItem(null);
                    }}
                />
            </div>
            <ToastContainer />
        </div>
    );
};

export default TextsComponent;