import React, { useState, useEffect, useCallback } from "react";
import CategoryForm from './CategoryForm';
import CategoryTable from './CategoryTable';
import { parsingConfigService, channelService, categoryService } from '../config/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CategoryComponent = () => {
    // Change channels state to store array instead of object
    const [channelsMap, setChannelsMap] = useState({});
    const [failedChannels, setFailedChannels] = useState(new Set());
    const [tableData, setTableData] = useState({
        items: [],
        total: 0,
        page: 1,
        pages: 1,
        per_page: 10
    });
    const [editItem, setEditItem] = useState(null);

    const [formData, setFormData] = useState({
        categoryId: "",
        telegramId: "",
        minDiscount: "",
        minPrice: "",
        maxPrice: "",
        minRating: "",
        pageRange: "",
        excludedBrands: "",
        excludedProductNames: "",
        isActive: false
    });

    const [selectedCategories, setSelectedCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    // Add new state for search
    const [configSearchQuery, setConfigSearchQuery] = useState('');
    const [isSearchingConfigs, setIsSearchingConfigs] = useState(false);

    // Keep only the essential methods for fetching channel names
    // Update fetchChannelName to use channelsMap
    // Оптимизированная версия fetchChannelName
    const fetchChannelName = useCallback(async (telegramId) => {
        if (!telegramId) return 'Не задано';
        if (channelsMap[telegramId] && channelsMap[telegramId] !== 'Загрузка...') {
            return channelsMap[telegramId];
        }
        if (failedChannels.has(telegramId)) return "Ошибка загрузки";

        // Проверяем, не выполняется ли уже запрос для этого ID
        if (channelsMap[telegramId] === 'Загрузка...') {
            return 'Загрузка...';
        }

        // Помечаем канал как загружаемый
        setChannelsMap(prev => ({
            ...prev,
            [telegramId]: 'Загрузка...'
        }));

        try {
            const response = await channelService.getChannelByTelegramId(telegramId);
            if (!response.ok) throw new Error();
            const data = await response.json();
            const channelName = data.name || "Не задано";

            setChannelsMap(prev => ({
                ...prev,
                [telegramId]: channelName,
            }));
            return channelName;
        } catch (error) {
            setFailedChannels(prev => new Set(prev).add(telegramId));
            setChannelsMap(prev => ({
                ...prev,
                [telegramId]: "Ошибка загрузки"
            }));
            return "Ошибка загрузки";
        }
    }, [channelsMap, failedChannels]);

    // Обновленная версия updateChannelNames с защитой от повторных запросов
    const updateChannelNames = useCallback(async (configs) => {
        if (!configs.length) return;

        // Получаем только уникальные ID, которые еще не загружены или не в процессе загрузки
        const uniqueTgIds = [...new Set(configs
            .map(item => item.telegram_id)
            .filter(id => !channelsMap[id] || channelsMap[id] === 'Загрузка...')
        )];

        // Загружаем последовательно с небольшой задержкой
        for (const tgId of uniqueTgIds) {
            await fetchChannelName(tgId);
            await new Promise(resolve => setTimeout(resolve, 100)); // Задержка 100мс между запросами
        }
    }, [fetchChannelName, channelsMap]);

    // Fetch table data
    useEffect(() => {
        let isMounted = true;

        const fetchTableData = async () => {
            try {
                const response = await parsingConfigService.getConfigs();
                if (!response.ok) throw new Error(`Failed to fetch table data: ${response.statusText}`);
                const data = await response.json();

                const configsWithExtras = data.items.map(config => ({
                    ...config,
                    categoryName: config.category?.name || 'Не задано',
                    channelName: channelsMap[config.telegram_id] || 'Загрузка...'
                }));

                if (isMounted) {
                    setTableData({
                        ...data,
                        items: configsWithExtras
                    });

                    await updateChannelNames(configsWithExtras);
                }
            } catch (err) {
                toast.error(`Error fetching table data: ${err.message}`);
            }
        };

        fetchTableData();
        return () => { isMounted = false; };
    }, [updateChannelNames, channelsMap]);

    // Add search handler
    const handleConfigSearch = useCallback(async (query) => {
        setIsSearchingConfigs(true);
        try {
            const response = await parsingConfigService.searchConfigs(query);
            if (!response.ok) throw new Error('Search failed');
            const data = await response.json();
            console.log('Search response:', data); // Debug log

            const configsWithExtras = (data || []).map(config => ({
                ...config,
                categoryName: config.category?.name || 'Не задано',
                channelName: channelsMap[config.telegram_id] || 'Загрузка...'
            }));

            setTableData(prevData => ({
                ...prevData,
                items: configsWithExtras,
                total: configsWithExtras.length,
                pages: Math.ceil(configsWithExtras.length / prevData.per_page)
            }));

            await updateChannelNames(configsWithExtras);
        } catch (error) {
            console.error('Search error:', error);
            toast.error('Ошибка при поиске конфигураций');
        } finally {
            setIsSearchingConfigs(false);
        }
    }, [channelsMap, updateChannelNames]);

    // Move handlePageChange up, before it's used
    const handlePageChange = useCallback(async (page) => {
        try {
            const response = await parsingConfigService.getConfigs(page, tableData.per_page);
            if (!response.ok) throw new Error('Failed to fetch table data');
            const data = await response.json();

            const configsWithExtras = data.items.map(config => ({
                ...config,
                categoryName: config.category?.name || 'Не задано',
                channelName: channelsMap[config.telegram_id] || 'Загрузка...'
            }));

            setTableData(prevData => ({
                ...prevData,
                items: configsWithExtras,
                page: page,
                total: data.total,
                pages: data.pages
            }));

            await updateChannelNames(configsWithExtras);
        } catch (error) {
            toast.error(`Error fetching table data: ${error.message}`);
        }
    }, [channelsMap, tableData.per_page, updateChannelNames]);

    // Add debounced search effect
    useEffect(() => {
        if (!configSearchQuery) {
            // Reset to original data when search is cleared
            handlePageChange(1);
            return;
        }

        const timer = setTimeout(() => {
            handleConfigSearch(configSearchQuery);
        }, 300);

        return () => clearTimeout(timer);
    }, [configSearchQuery, handlePageChange, handleConfigSearch]);

    // Keep the rest of your existing methods (handleEdit, handleDelete, handleSubmit, etc.)
    const handleEdit = (item) => {
        // Update form data with selected item values
        setFormData({
            categoryId: item.category_id,
            telegramId: item.telegram_id || "",
            minDiscount: item.min_discount || "",
            minPrice: item.min_price || "",
            maxPrice: item.max_price || "",
            minRating: item.min_rating || "",
            pageRange: item.page_range || "",
            excludedBrands: item.excluded_brands?.join(", ") || "",
            excludedProductNames: item.excluded_product_names?.join(", ") || "",
            isActive: item.is_active
        });

        // Set selected category
        setSelectedCategories([{
            id: item.category_id,
            full_path: item.categoryName
        }]);

        // Set edit mode
        setEditItem(item);

        // Reset any previous search
        setSearchQuery('');
        setSearchResults([]);
    };

    const handleDelete = async (id) => {
        try {
            const response = await parsingConfigService.deleteConfig(id);

            if (!response.ok) {
                throw new Error('Failed to delete config');
            }

            setTableData(prevData => ({
                ...prevData,
                items: prevData.items.filter(item => item.id !== id),
                total: prevData.total - 1
            }));
            toast.success('Конфигурация успешно удалена');
        } catch (error) {
            toast.error(`Ошибка при удалении: ${error.message}`);
        }
    };

    const handleInputChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: type === "checkbox" ? checked : value
        }));
    };

    const handleCategorySearch = async (query) => {
        if (!query) {
            setSearchResults([]);
            return;
        }

        setIsSearching(true);
        try {
            const response = await categoryService.searchCategoryByQuery(query);
            if (!response.ok) throw new Error('Search failed');
            const data = await response.json();
            setSearchResults(data);
        } catch (error) {
            console.error('Search error:', error);
            setSearchResults([]);
        } finally {
            setIsSearching(false);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            handleCategorySearch(searchQuery);
        }, 300);

        return () => clearTimeout(timer);
    }, [searchQuery]);

    const handleCategorySelect = (category) => {
        setSelectedCategories(prev => [...prev, category]);
        setSearchQuery('');
        setSearchResults([]);
    };

    const handleRemoveCategory = (categoryId) => {
        setSelectedCategories(prev => prev.filter(cat => cat.id !== categoryId));
    };

    // Добавляем состояние для каналов
    // Update channelsList state and fetching
    const [channelsList, setChannelsList] = useState([]);

    // Загружаем список каналов при монтировании
    useEffect(() => {
        const fetchChannels = async () => {
            try {
                const response = await channelService.getChannels(1, 99);
                if (!response.ok) throw new Error('Failed to fetch channels');
                const data = await response.json();
                setChannelsList(data.items || []);
            } catch (error) {
                toast.error('Error fetching channels');
            }
        };

        fetchChannels();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (editItem) {
                // Handle edit case
                const requestData = {
                    category_id: editItem.category_id,
                    telegram_id: formData.telegramId,
                    min_discount: parseFloat(formData.minDiscount) || 0,
                    min_price: parseFloat(formData.minPrice) || 0,
                    max_price: parseFloat(formData.maxPrice) || 0,
                    min_rating: parseFloat(formData.minRating) || 0,
                    page_range: parseInt(formData.pageRange) || 0,
                    type: "category",
                    excluded_brands: formData.excludedBrands
                        ? formData.excludedBrands.split(",").map(brand => brand.trim())
                        : [],
                    excluded_product_names: formData.excludedProductNames
                        ? formData.excludedProductNames.split(",").map(name => name.trim())
                        : [],
                    is_active: formData.isActive
                };

                const response = await parsingConfigService.updateConfig(editItem.id, requestData);
                const updatedConfig = await response.json();

                setTableData(prevData => ({
                    ...prevData,
                    items: prevData.items.map(item =>
                        item.id === editItem.id
                            ? { ...item, ...updatedConfig, categoryName: item.categoryName }
                            : item
                    )
                }));

                toast.success('Конфигурация успешно обновлена!');

                // Reset form and edit mode
                setFormData({
                    categoryId: "",
                    telegramId: "",
                    minDiscount: "",
                    minPrice: "",
                    maxPrice: "",
                    minRating: "",
                    pageRange: "",
                    excludedBrands: "",
                    excludedProductNames: "",
                    isActive: false
                });
                setSelectedCategories([]);
                setEditItem(null);
            } else {
                // Handle create case - existing code for creating new entries
                const requests = selectedCategories.map(category => {
                    const requestData = {
                        category_id: category.id,
                        telegram_id: formData.telegramId,
                        min_discount: parseFloat(formData.minDiscount) || 10,
                        min_price: parseFloat(formData.minPrice) || 0,
                        max_price: parseFloat(formData.maxPrice) || 0,
                        min_rating: parseFloat(formData.minRating) || 0,
                        page_range: parseInt(formData.pageRange) || 0,
                        type: "category",
                        excluded_brands: formData.excludedBrands
                            ? formData.excludedBrands.split(",").map(brand => brand.trim())
                            : [],
                        excluded_product_names: formData.excludedProductNames
                            ? formData.excludedProductNames.split(",").map(name => name.trim())
                            : [],
                        is_active: formData.isActive
                    };

                    return parsingConfigService.createConfig(requestData);
                });

                const results = await Promise.all(requests);
                const errors = results.filter(r => !r.ok);

                if (errors.length > 0) {
                    throw new Error(`Failed to submit ${errors.length} requests`);
                }

                const jsonResults = await Promise.all(results.map(r => r.json()));

                // Add category names to the new entries using full_path
                const newEntries = jsonResults.map(result => {
                    const category = selectedCategories.find(cat => cat.id === result.category_id);
                    return {
                        ...result,
                        categoryName: category ? category.full_path : 'Не задано'
                    };
                });

                // Update table data with new entries including category names
                setTableData(prev => ({
                    ...prev,
                    items: [...prev.items, ...newEntries],
                    total: prev.total + newEntries.length
                }));

                // Reset form
                setFormData({
                    categoryId: "",
                    telegramId: "",
                    minDiscount: "",
                    minPrice: "",
                    maxPrice: "",
                    minRating: "",
                    pageRange: "",
                    excludedBrands: "",
                    excludedProductNames: "",
                    isActive: false
                });
                setSelectedCategories([]);
                setEditItem(null);

                toast.success('Категории успешно добавлены!');
            }
        } catch (err) {
            toast.error(`Ошибка: ${err.message}`);
        }
    };

    return (
        <>
            <div className="row g-4">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="input-group">
                                <span className="input-group-text">
                                    <i className="fas fa-search"></i>
                                </span>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Поиск по названию категории или Telegram ID..."
                                    value={configSearchQuery}
                                    onChange={(e) => setConfigSearchQuery(e.target.value)}
                                />
                                {isSearchingConfigs && (
                                    <span className="input-group-text">
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">Поиск...</span>
                                        </div>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <CategoryForm
                    formData={formData}
                    editItem={editItem}
                    searchQuery={searchQuery}
                    isSearching={isSearching}
                    searchResults={searchResults}
                    selectedCategories={selectedCategories}
                    onSearchQueryChange={setSearchQuery}
                    onCategorySelect={handleCategorySelect}
                    onRemoveCategory={handleRemoveCategory}
                    onInputChange={handleInputChange}
                    onSubmit={handleSubmit}
                    onClear={() => {
                        setFormData({
                            categoryId: "",
                            telegramId: "",
                            minDiscount: "",
                            minPrice: "",
                            maxPrice: "",
                            minRating: "",
                            pageRange: "",
                            excludedBrands: "",
                            excludedProductNames: "",
                            isActive: false
                        });
                        setEditItem(null);
                    }}
                    channels={channelsList}
                />
                <CategoryTable
                    tableData={tableData.items}
                    totalItems={tableData.total}
                    currentPage={tableData.page}
                    totalPages={tableData.pages}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onPageChange={handlePageChange}
                    pageSize={tableData.per_page}
                />
            </div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                pauseOnHover
            />
        </>
    );
};

export default CategoryComponent;
