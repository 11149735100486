import React, { useState, useEffect } from 'react';
import { Line, LineChart, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import CountUp from 'react-countup';
import { analyticsService } from '../config/api';
import { IconUsers, IconUserCheck, IconSearch, IconUserPlus } from '@tabler/icons-react';
const StatsCard = ({ title, value, icon: Icon }) => (
    <div className="card h-100 shadow-sm border-0">
        <div className="card-body">
            <div className="d-flex align-items-center mb-3">
                {Icon && <Icon size={24} className="text-primary me-2" />}
                <h6 className="card-subtitle text-muted mb-0">{title}</h6>
            </div>
            <h2 className="card-title mb-0">
                <CountUp end={value} separator=" " duration={2} />
            </h2>
        </div>
    </div>
);

const periodLabels = {
    'week': 'Неделя',
    'month': 'Месяц',
    'year': 'Год'
};

const Dashboard = () => {
    const [timeframe, setTimeframe] = useState('week');
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const data = await analyticsService.getAnalytics(timeframe);
                const response = await data.json();
                setStats(response);
            } catch (error) {
                console.error('Error fetching analytics:', error);
            }
            setLoading(false);
        };

        fetchData();
    }, [timeframe]);

    const formatDate = (date) => {
        const d = new Date(date);
        return d.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: 'short',
            year: timeframe === 'year' ? 'numeric' : undefined
        });
    };

    if (loading || !stats) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '400px' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Загрузка...</span>
                </div>
            </div>
        );
    }

    const chartData = Object.entries(stats.growth_data).map(([date, value]) => ({
        date: formatDate(date),
        users: value
    }));

    return (
        <div>
            <h1 className="h3 mb-4">Аналитика</h1>
            <div className="row g-4 mb-4">
                <div className="col-md-3">
                    <StatsCard
                        title="Всего пользователей"
                        value={stats.total_users}
                        icon={IconUsers}
                    />
                </div>
                <div className="col-md-3">
                    <StatsCard
                        title="Активные пользователи"
                        value={stats.active_users}
                        icon={IconUserPlus}
                    />
                </div>
                <div className="col-md-3">
                    <StatsCard
                        title="Стандартные подписки"
                        value={stats.subscribed_users.standard}
                        icon={IconUserCheck}
                    />
                </div>
                <div className="col-md-3">
                    <StatsCard
                        title="Поисковые подписки"
                        value={stats.subscribed_users.search}
                        icon={IconSearch}
                    />
                </div>

            </div>

            <div className="card shadow-sm border-0"></div>
            <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h5 className="card-title mb-0">Динамика роста</h5>
                    <div className="btn-group">
                        {Object.entries(periodLabels).map(([period, label]) => (
                            <button
                                key={period}
                                type="button"
                                className={`btn ${timeframe === period ? 'btn-primary' : 'btn-outline-primary'}`}
                                onClick={() => setTimeframe(period)}
                            >
                                {label}
                            </button>
                        ))}
                    </div>
                </div>

                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={chartData}>
                        <XAxis
                            dataKey="date"
                            tick={{ fill: '#666' }}
                            tickLine={{ stroke: '#666' }}
                        />
                        <YAxis
                            tick={{ fill: '#666' }}
                            tickLine={{ stroke: '#666' }}
                        />
                        <Tooltip
                            contentStyle={{
                                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                                border: 'none',
                                borderRadius: '8px',
                                boxShadow: '0 0 10px rgba(0,0,0,0.1)'
                            }}
                        />
                        <Line
                            type="monotone"
                            dataKey="users"
                            stroke="#0d6efd"
                            strokeWidth={2}
                            dot={{ fill: '#0d6efd', strokeWidth: 2 }}
                            name="Пользователей"
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default Dashboard;
