import React from 'react';
import { FaTimes, FaPen } from "react-icons/fa";
import DataTable from './common/DataTable';

const BroadcastTable = ({ 
    broadcasts, 
    totalItems,
    currentPage,
    totalPages,
    onEdit, 
    onDelete,
    onPageChange,
    pageSize = 10
}) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('ru-RU', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const getStatusText = (status) => {
        const statusMap = {
            'scheduled': 'Запланировано',
            'sent': 'Отправлено',
            'cancelled': 'Отменено'
        };
        return statusMap[status] || status;
    };

    const columns = [
        { 
            field: 'scheduled_date', 
            header: 'Дата отправки', 
            sortable: true,
            render: (item) => formatDate(item.scheduled_date)
        },
        { 
            field: 'subscribed_users', 
            header: 'Целевая аудитория', 
            sortable: true,
            render: (item) => {
                if (item.subscribed_users === null) return 'Все пользователи';
                return item.subscribed_users ? 'С подпиской' : 'Без подписки';
            }
        },
        { 
            field: 'status', 
            header: 'Статус', 
            sortable: true,
            render: (item) => getStatusText(item.status)
        },
        { 
            field: 'text_preview', 
            header: 'Предпросмотр сообщения', 
            sortable: false,
            render: (item) => {
                const preview = item.text_preview || '';
                return preview.length > 50 ? `${preview.slice(0, 50)}...` : preview;
            }
        },
        {
            field: 'actions',
            header: 'Действия',
            sortable: false,
            render: (item) => (
                <div className="action-btns">
                    <button
                        onClick={() => onEdit(item)}
                        className="btn btn-warning btn-sm"
                    >
                        <FaPen size={16} />
                    </button>
                    <button
                        onClick={() => onDelete(item.id)}
                        className="btn btn-danger btn-sm"
                    >
                        <FaTimes size={16} />
                    </button>
                </div>
            )
        }
    ];

    return (
        <DataTable
            data={broadcasts}
            columns={columns}
            totalItems={totalItems}
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={onPageChange}
            title="Рассылки"
        />
    );
};

export default BroadcastTable;