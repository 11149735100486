export const TEXTS = {
    LOGIN: {
        TITLE: 'Вход в панель управления',
        USERNAME: 'Имя пользователя',
        PASSWORD: 'Пароль',
        SUBMIT: 'Войти в систему',
        LOADING: 'Выполняется вход...',
        ERROR: 'Неверное имя пользователя или пароль'
    },
    SIDEBAR: {
        DASHBOARD: 'Главная',
        CATEGORIES: 'Категории',
        BROADCASTS: 'Рассылки',
        CHANNELS: 'Каналы',
        PROMO_CODES: 'Промокоды',
        SUBSCRIPTION_PLANS: 'Тарифы',
        TEXTS: 'Тексты',
        LOGOUT: 'Выйти'
    }
};