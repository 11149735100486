import React, { useState } from 'react';

const ChannelForm = ({ formData, editItem, onInputChange, onSubmit, onClear }) => {
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!formData.telegram_id) newErrors.telegram_id = 'Необходимо указать Telegram ID';
        if (!formData.name) newErrors.name = 'Необходимо указать название канала';
        if (!formData.url) newErrors.url = 'Необходимо указать URL канала';
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        onSubmit(e);
    };

    return (
        <div className="col-12 col-lg-4">
            <div className="alert alert-info mb-4" role="alert">
                <h5 className="alert-heading">⚠️ Важное напоминание</h5>
                <p className="mb-0">Перед началом работы убедитесь, что вы добавили бота в качестве администратора в ваш Telegram-канал для корректной работы системы публикаций.</p>
            </div>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title mb-4">
                        {editItem ? 'Редактирование канала' : 'Добавление нового канала'}
                    </h5>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">
                                Telegram ID <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className={`form-control ${errors.telegram_id ? 'is-invalid' : ''}`}
                                id="telegram_id"
                                value={formData.telegram_id}
                                onChange={onInputChange}
                                placeholder="Введите Telegram ID канала"
                            />
                            <small className="text-muted">
                                Чтобы получить ID канала, отправьте канал боту <a href="https://t.me/username_to_id_bot" target="_blank" rel="noopener noreferrer">@username_to_id_bot</a>
                            </small>
                            {errors.telegram_id && (
                                <div className="invalid-feedback">{errors.telegram_id}</div>
                            )}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">
                                Название канала <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                id="name"
                                value={formData.name}
                                onChange={onInputChange}
                                placeholder="Введите название канала"
                            />
                            {errors.name && (
                                <div className="invalid-feedback">{errors.name}</div>
                            )}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">
                                Ссылка-приглашение <span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                className={`form-control ${errors.url ? 'is-invalid' : ''}`}
                                id="url"
                                value={formData.url}
                                onChange={onInputChange}
                                placeholder="Введите ссылку-приглашение в канал"
                            />
                            {errors.url && (
                                <div className="invalid-feedback">{errors.url}</div>
                            )}
                        </div>

                        <div className="text-muted small mb-3">
                            <span className="text-danger">*</span> - обязательные поля
                        </div>

                        <div className="d-flex gap-2">
                            <button type="submit" className="btn btn-primary">
                                {editItem ? 'Сохранить изменения' : 'Добавить канал'}
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-secondary"
                                onClick={() => {
                                    onClear();
                                    setErrors({});
                                }}
                            >
                                {editItem ? 'Отменить' : 'Очистить форму'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChannelForm;