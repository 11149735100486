
import React, { useState, useEffect } from 'react';
import { settingsService } from '../config/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SettingsComponent = () => {
    const [referralDays, setReferralDays] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadReferralDays();
    }, []);

    const loadReferralDays = async () => {
        try {
            const response = await settingsService.getReferralBonusDays();
            if (!response.ok) throw new Error('Failed to fetch referral bonus days');
            const data = await response.json();
            setReferralDays(data.days);
        } catch (error) {
            toast.error('Ошибка при загрузке настроек');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await settingsService.updateReferralBonusDays(referralDays);
            if (!response.ok) throw new Error('Failed to update referral bonus days');
            toast.success('Настройки успешно сохранены');
        } catch (error) {
            toast.error('Ошибка при сохранении настроек');
        }
    };

    if (isLoading) {
        return <div>Загрузка...</div>;
    }

    return (
        <div className="container">
            <h2 className="mb-4">Настройки</h2>
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Реферальная программа</h5>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="referralDays" className="form-label">
                                Количество бонусных дней для реферала и пригласившего
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="referralDays"
                                value={referralDays}
                                onChange={(e) => setReferralDays(Number(e.target.value))}
                                min="0"
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default SettingsComponent;