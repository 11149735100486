import React from 'react';

const BroadcastForm = ({ broadcast, onSubmit, onCancel }) => {
    const [formData, setFormData] = React.useState({
        scheduled_date: broadcast?.scheduled_date?.slice(0, 16) || '', // Get only date and time part
        subscribed_users: broadcast?.subscribed_users
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(formData);
    };

    return (
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title">Редактировать рассылку</h5>
                <button type="button" className="btn-close" onClick={onCancel}></button>
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Дата и время отправки</label>
                        <input 
                            type="datetime-local"
                            className="form-control"
                            value={formData.scheduled_date}
                            onChange={(e) => setFormData(prev => ({ 
                                ...prev, 
                                scheduled_date: e.target.value 
                            }))}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Целевая аудитория</label>
                        <select 
                            className="form-select"
                            value={formData.subscribed_users === null ? 'all' : formData.subscribed_users.toString()}
                            onChange={(e) => {
                                const value = e.target.value;
                                setFormData(prev => ({
                                    ...prev,
                                    subscribed_users: value === 'all' ? null : value === 'true'
                                }));
                            }}
                        >
                            <option value="all">Все пользователи</option>
                            <option value="true">С подпиской</option>
                            <option value="false">Без подписки</option>
                        </select>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onCancel}>
                            Отмена
                        </button>
                        <button type="submit" className="btn btn-primary">
                            Сохранить
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BroadcastForm;