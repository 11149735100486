import React from 'react';
import { FaTimes, FaPen } from "react-icons/fa";
import DataTable from './common/DataTable';

const PromoCodeTable = ({ 
    promoCodes, 
    totalItems,
    currentPage,
    totalPages,
    onEdit, 
    onDelete,
    onPageChange,
    pageSize = 10
}) => {
    const columns = [
        { field: 'code', header: 'Промокод', sortable: true },
        { 
            field: 'discount_percentage', 
            header: 'Скидка', 
            sortable: true,
            render: (item) => item.discount_percentage ? `${item.discount_percentage}%` : '-'
        },
        { 
            field: 'days_free', 
            header: 'Бесплатных дней', 
            sortable: true,
            render: (item) => item.days_free || '-'
        },
        { 
            field: 'stats',
            header: 'Использований',
            sortable: true,
            render: (item) => (item.stats?.uses_count ?? 0)
        },
        { 
            field: 'stats',
            header: 'Купили подписку',
            sortable: true,
            render: (item) => (item.stats?.buy_subs ?? 0)
        },
        { 
            field: 'expiration_date', 
            header: 'Действует до', 
            sortable: true,
            render: (item) => item.expiration_date ? new Date(item.expiration_date).toLocaleString() : '-'
        },
        {
            field: 'actions',
            header: 'Действия',
            render: (item) => (
                <div className="action-btns">
                    <button onClick={() => onEdit(item)} className="btn btn-warning btn-sm">
                        <FaPen size={16} />
                    </button>
                    <button onClick={() => onDelete(item.id)} className="btn btn-danger btn-sm">
                        <FaTimes size={16} />
                    </button>
                </div>
            )
        }
    ];

    return (
        <div className="col-12 col-lg-8">
            <DataTable
                data={promoCodes}
                columns={columns}
                totalItems={totalItems}
                currentPage={currentPage}
                totalPages={totalPages}
                pageSize={pageSize}
                onPageChange={onPageChange}
                title="Промокоды"
            />
        </div>
    );
};

export default PromoCodeTable;