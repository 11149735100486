import React from 'react';
import { FaTimes, FaPen } from "react-icons/fa";
import DataTable from './common/DataTable';

const ChannelTable = ({ 
    channels, 
    totalItems,
    currentPage,
    totalPages,
    onEdit, 
    onDelete,
    onPageChange,
    pageSize = 10
}) => {
    const columns = [
        { field: 'telegram_id', header: 'Telegram ID', sortable: true },
        { field: 'name', header: 'Название', sortable: true },
        { 
            field: 'url', 
            header: 'Ссылка-приглашение',
            render: (item) => (
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                    {item.url}
                </a>
            )
        },
        {
            field: 'actions',
            header: 'Действия',
            sortable: false,
            render: (item) => (
                <div className="action-btns">
                    <button
                        onClick={() => onEdit(item)}
                        className="btn btn-warning btn-sm"
                    >
                        <FaPen size={16} />
                    </button>
                    <button
                        onClick={() => onDelete(item.id)}
                        className="btn btn-danger btn-sm"
                    >
                        <FaTimes size={16} />
                    </button>
                </div>
            )
        }
    ];

    return (
        <div className="col-12 col-lg-8">
            <DataTable
                data={channels}
                columns={columns}
                totalItems={totalItems}
                currentPage={currentPage}
                totalPages={totalPages}
                pageSize={pageSize}
                onPageChange={onPageChange}
                title="Каналы"
            />
        </div>
    );
};

export default ChannelTable;