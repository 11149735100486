import React, { useState } from "react";
import Sidebar from "./components/Sidebar";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import CategoryComponent from "./components/CategoryComponent";
import BroadCastComponent from "./components/BroadcastComponent";
import ChannelsComponent from "./components/ChannelsComponent";
import PromoCodesComponent from "./components/PromoCodesComponent";
import SubscriptionPlansComponent from "./components/SubscriptionPlansComponent";
import TextsComponent from "./components/TextsComponent";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider, useAuth } from "./context/AuthContext";
import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Dashboard";
import SettingsComponent from "./components/SettingsComponent";



const PrivateRoute = ({ children }) => {
    const { isAuthenticated, isLoading } = useAuth();

    if (isLoading) {
        return <div>Загрузка...</div>;
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
};

const PrivateLayout = ({ children }) => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const { logout } = useAuth();

    return (
        <div className="d-flex min-vh-100">
            <Sidebar onCollapseChange={setSidebarCollapsed} onLogout={logout} />
            <div
                className="flex-grow-1 transition-margin p-3 p-md-4"
                style={{
                    marginLeft: sidebarCollapsed ? "80px" : "250px",
                    transition: "margin-left 0.3s"
                }}
            >
                <div className="container-fluid">
                    {children}
                </div>
            </div>
        </div>
    );
};

function App() {
    return (
        <AuthProvider>
            <Router basename="/">
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/*" element={
                        <PrivateRoute>
                            <PrivateLayout>
                                <Routes>
                                    <Route path="/" element={<Dashboard />} />
                                    <Route path="/category" element={<CategoryComponent />} />
                                    <Route path="/broadcast" element={<BroadCastComponent />} />
                                    <Route path="/channels" element={<ChannelsComponent />} />
                                    <Route path="/promo-codes" element={<PromoCodesComponent />} />
                                    <Route path="/sub-plans" element={<SubscriptionPlansComponent />} />
                                    <Route path="/texts" element={<TextsComponent />} />
                                    <Route path="/settings" element={<SettingsComponent />} />
                                </Routes>
                            </PrivateLayout>
                        </PrivateRoute>
                    } />
                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
