import React, { useState } from 'react';

const SubscriptionPlanForm = ({ formData, editItem, onInputChange, onSubmit, onClear }) => {
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = 'Необходимо указать название';
        }
        if (!formData.price) {
            newErrors.price = 'Необходимо указать цену';
        }
        if (!formData.duration_days) {
            newErrors.duration_days = 'Необходимо указать длительность';
        }
        if (!formData.type) {
            newErrors.type = 'Необходимо указать тип подписки';
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        onSubmit(e);
    };

    return (
        <div className="col-12 col-lg-4">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title mb-4">
                        {editItem ? 'Редактирование плана' : 'Добавление плана'}
                    </h5>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Название <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                id="name"
                                value={formData.name || ''}
                                onChange={onInputChange}
                                placeholder="Введите название плана"
                            />
                            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Цена <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className={`form-control ${errors.price ? 'is-invalid' : ''}`}
                                id="price"
                                value={formData.price || ''}
                                onChange={onInputChange}
                                placeholder="Введите цену"
                            />
                            {errors.price && <div className="invalid-feedback">{errors.price}</div>}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Длительность (дней) <span className="text-danger">*</span></label>
                            <input
                                type="number"
                                className={`form-control ${errors.duration_days ? 'is-invalid' : ''}`}
                                id="duration_days"
                                value={formData.duration_days || ''}
                                onChange={onInputChange}
                                placeholder="Количество дней"
                            />
                            {errors.duration_days && <div className="invalid-feedback">{errors.duration_days}</div>}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Тип подписки <span className="text-danger">*</span></label>
                            <select
                                className={`form-select ${errors.type ? 'is-invalid' : ''}`}
                                id="type"
                                value={formData.type || ''}
                                onChange={onInputChange}
                            >
                                <option value="">Выберите тип</option>
                                <option value="standard">Стандарт (категории)</option>
                                <option value="search">Индивидуальный поиск</option>
                                <option value="trial">Пробный период</option>
                            </select>
                            {errors.type && <div className="invalid-feedback">{errors.type}</div>}
                        </div>

                        <div className="d-flex gap-2">
                            <button type="submit" className="btn btn-primary">
                                {editItem ? 'Сохранить изменения' : 'Добавить план'}
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={onClear}>
                                {editItem ? 'Отменить' : 'Очистить форму'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionPlanForm;