import React from 'react';
import { FaTimes, FaPen } from "react-icons/fa";
import DataTable from './common/DataTable';

const SubscriptionPlanTable = ({ 
    plans, 
    totalItems,
    currentPage,
    totalPages,
    onEdit, 
    onDelete,
    onPageChange,
    pageSize = 10
}) => {
    const columns = [
        { field: 'name', header: 'Название', sortable: true },
        { 
            field: 'price', 
            header: 'Цена', 
            sortable: true,
            render: (item) => `${item.price} ₽`
        },
        { 
            field: 'duration_days', 
            header: 'Длительность', 
            sortable: true,
            render: (item) => `${item.duration_days} дней`
        },
        { 
            field: 'type', 
            header: 'Тип', 
            sortable: true,
            render: (item) => {
                switch(item.type) {
                    case 'standard': return 'Стандарт';
                    case 'search': return 'Индивидуальный поиск';
                    case 'trial': return 'Пробный период';
                    default: return item.type;
                }
            }
        },
        {
            field: 'actions',
            header: 'Действия',
            render: (item) => (
                <div className="action-btns">
                    <button onClick={() => onEdit(item)} className="btn btn-warning btn-sm">
                        <FaPen size={16} />
                    </button>
                    <button onClick={() => onDelete(item.id)} className="btn btn-danger btn-sm">
                        <FaTimes size={16} />
                    </button>
                </div>
            )
        }
    ];

    return (
        <div className="col-12 col-lg-8">
            <DataTable
                data={plans}
                columns={columns}
                totalItems={totalItems}
                currentPage={currentPage}
                totalPages={totalPages}
                pageSize={pageSize}
                onPageChange={onPageChange}
                title="Планы подписки"
            />
        </div>
    );
};

export default SubscriptionPlanTable;