import React, { useState, useEffect, useCallback } from "react";
import PromoCodeForm from './PromoCodeForm';
import PromoCodeTable from './PromoCodeTable';
import { promoCodeService } from '../config/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PromoCodesComponent = () => {
    const [promoCodesData, setPromoCodesData] = useState({
        items: [],
        total: 0,
        page: 1,
        pages: 1,
        per_page: 10
    });
    const [editItem, setEditItem] = useState(null);
    const [formData, setFormData] = useState({
        code: null,
        discount_percentage: null,
        days_free: null,
        expiration_date: null
    });

    const handlePageChange = useCallback(async (page) => {
        try {
            const response = await promoCodeService.getCodes(page, promoCodesData.per_page);
            if (!response.ok) throw new Error('Failed to fetch promo codes');
            const data = await response.json();
            setPromoCodesData(prevData => ({
                ...prevData,
                items: data.items,
                page: page,
                total: data.total,
                pages: data.pages
            }));
        } catch (error) {
            toast.error('Error fetching promo codes');
        }
    }, [promoCodesData.per_page]);

    useEffect(() => {
        handlePageChange(1);
    }, [handlePageChange]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = editItem
                ? await promoCodeService.updateCode(editItem.id, formData)
                : await promoCodeService.createCode(formData);

            if (!response.ok) throw new Error('Failed to save promo code');

            const savedPromoCode = await response.json();
            
            if (editItem) {
                setPromoCodesData(prevData => ({
                    ...prevData,
                    items: prevData.items.map(code => 
                        code.id === editItem.id ? savedPromoCode : code
                    )
                }));
                toast.success('Промокод успешно обновлен');
            } else {
                handlePageChange(1);
                toast.success('Промокод успешно добавлен');
            }

            setFormData({
                code: null,
                discount_percentage: null,
                days_free: null,
                expiration_date: null
            });
            setEditItem(null);
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleEdit = (item) => {
        setFormData({
            code: item.code,
            discount_percentage: item.discount_percentage,
            days_free: item.days_free,
            expiration_date: item.expiration_date
        });
        setEditItem(item);
    };

    const handleDelete = async (id) => {
        try {
            const response = await promoCodeService.deleteCode(id);
            if (!response.ok) throw new Error('Failed to delete promo code');

            setPromoCodesData(prevData => ({
                ...prevData,
                items: prevData.items.filter(code => code.id !== id),
                total: prevData.total - 1
            }));
            toast.success('Промокод успешно удален');
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    };

    return (
        <div className="row g-4">
            <PromoCodeForm
                formData={formData}
                editItem={editItem}
                onInputChange={handleInputChange}
                onSubmit={handleSubmit}
                onClear={() => {
                    setFormData({
                        code: null,
                        discount_percentage: null,
                        days_free: null,
                        expiration_date: null
                    });
                    setEditItem(null);
                }}
            />
            <PromoCodeTable
                promoCodes={promoCodesData.items}
                totalItems={promoCodesData.total}
                currentPage={promoCodesData.page}
                totalPages={promoCodesData.pages}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onPageChange={handlePageChange}
                pageSize={promoCodesData.per_page}
            />
            <ToastContainer />
        </div>
    );
};

export default PromoCodesComponent;