import React, { useState } from 'react';

const PromoCodeForm = ({ formData, editItem, onInputChange, onSubmit, onClear }) => {
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!formData.code) {
            newErrors.code = 'Необходимо указать код';
        }
        
        // Check if either discount or free days is provided
        if (!formData.discount_percentage && !formData.days_free) {
            newErrors.discount = 'Укажите процент скидки или количество бесплатных дней';
            newErrors.days_free = 'Необходимо заполнить хотя бы одно из полей';
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        onSubmit(e);
    };

    return (
        <div className="col-12 col-lg-4">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title mb-4">
                        {editItem ? 'Редактирование промокода' : 'Добавление промокода'}
                    </h5>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Код <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className={`form-control ${errors.code ? 'is-invalid' : ''}`}
                                id="code"
                                value={formData.code || ''}
                                onChange={onInputChange}
                                placeholder="Введите промокод"
                            />
                            {errors.code && <div className="invalid-feedback">{errors.code}</div>}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Скидка (%) <span className="text-danger">**</span></label>
                            <input
                                type="number"
                                className={`form-control ${errors.discount ? 'is-invalid' : ''}`}
                                id="discount_percentage"
                                value={formData.discount_percentage || ''}
                                onChange={onInputChange}
                                placeholder="Введите процент скидки"
                            />
                            {errors.discount && <div className="invalid-feedback">{errors.discount}</div>}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Бесплатных дней <span className="text-danger">**</span></label>
                            <input
                                type="number"
                                className={`form-control ${errors.days_free ? 'is-invalid' : ''}`}
                                id="days_free"
                                value={formData.days_free || ''}
                                onChange={onInputChange}
                                placeholder="Количество бесплатных дней"
                            />
                            {errors.days_free && <div className="invalid-feedback">{errors.days_free}</div>}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">Действует до</label>
                            <input
                                type="datetime-local"
                                className="form-control"
                                id="expiration_date"
                                value={formData.expiration_date ? formData.expiration_date.slice(0, 16) : ''}
                                onChange={onInputChange}
                            />
                        </div>

                        <div className="text-muted small mb-3">
                            <span className="text-danger">*</span> - обязательные поля<br/>
                            <span className="text-danger">**</span> - необходимо заполнить одно из полей
                        </div>

                        <div className="d-flex gap-2">
                            <button type="submit" className="btn btn-primary">
                                {editItem ? 'Сохранить изменения' : 'Добавить промокод'}
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={onClear}>
                                {editItem ? 'Отменить' : 'Очистить форму'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PromoCodeForm;