import React, { useState, useEffect, useCallback } from "react";
import BroadcastTable from './BroadcastTable';
import BroadcastForm from './BroadcastForm';
import { broadcastService } from '../config/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BroadcastComponent = () => {
    const [broadcastsData, setBroadcastsData] = useState({
        items: [],
        total: 0,
        page: 1,
        pages: 1,
        per_page: 10
    });
    const [editingBroadcast, setEditingBroadcast] = useState(null);

    const handlePageChange = useCallback(async (page) => {
        try {
            const response = await broadcastService.getBroadcasts(page, broadcastsData.per_page);
            if (!response.ok) throw new Error('Failed to fetch broadcasts');
            const data = await response.json();
            setBroadcastsData(prevData => ({
                ...prevData,
                items: data.items,
                page: page,
                total: data.total,
                pages: data.pages
            }));
        } catch (error) {
            toast.error('Error fetching broadcasts');
        }
    }, [broadcastsData.per_page]);

    useEffect(() => {
        handlePageChange(1);
    }, [handlePageChange]);

    const handleEdit = async (item) => {
        setEditingBroadcast(item);
    };

    const handleUpdate = async (formData) => {
        try {
            const response = await broadcastService.updateBroadcast(editingBroadcast.id, formData);
            if (!response.ok) throw new Error('Не удалось обновить рассылку');

            const updatedBroadcast = await response.json();
            setBroadcastsData(prevData => ({
                ...prevData,
                items: prevData.items.map(broadcast =>
                    broadcast.id === editingBroadcast.id ? updatedBroadcast : broadcast
                )
            }));

            setEditingBroadcast(null);
            toast.success('Рассылка успешно обновлена');
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await broadcastService.deleteBroadcast(id);
            if (!response.ok) throw new Error('Не удалось удалить рассылку');

            setBroadcastsData(prevData => ({
                ...prevData,
                items: prevData.items.filter(broadcast => broadcast.id !== id),
                total: prevData.total - 1
            }));
            toast.success('Рассылка успешно удалена');
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="alert alert-info mb-4" role="alert">
                    <h5 className="alert-heading">ℹ️ Создание рассылки</h5>
                    <p className="mb-0">
                        Для создания новой рассылки перейдите в{' '}
                        <a href="https://t.me/lisswbbot" target="_blank" rel="noopener noreferrer">
                            бота
                        </a>
                    </p>
                </div>
                {editingBroadcast && (
                    <>
                        <div className="modal-backdrop fade show" style={{ opacity: '0.5' }}></div>
                        <div className="modal show" style={{ display: 'block' }} tabIndex="-1">
                            <div className="modal-dialog">
                                <BroadcastForm
                                    broadcast={editingBroadcast}
                                    onSubmit={handleUpdate}
                                    onCancel={() => setEditingBroadcast(null)}
                                />
                            </div>
                        </div>
                    </>
                )}
                <BroadcastTable
                    broadcasts={broadcastsData.items}
                    totalItems={broadcastsData.total}
                    currentPage={broadcastsData.page}
                    totalPages={broadcastsData.pages}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onPageChange={handlePageChange}
                    pageSize={broadcastsData.per_page}
                />
                <ToastContainer />
            </div>
        </div>
    );
};

export default BroadcastComponent;
