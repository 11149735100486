import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { TEXTS } from '../constants/texts';
import '../styles/variables.css';

const LoginPage = () => {
    const [credentials, setCredentials] = useState({ username: '', password: '' });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        
        try {
            await login(credentials.username, credentials.password);
            navigate('/');
        } catch (err) {
            setError(TEXTS.LOGIN.ERROR);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center" 
             style={{ backgroundColor: 'var(--background-light)' }}>
            <div className="row justify-content-center w-100">
                <div className="col-md-6 col-lg-4">
                    <div className="card border-0" style={{ boxShadow: 'var(--card-shadow)' }}>
                        <div className="card-body p-5">
                            <h2 className="text-center mb-4 fw-bold" 
                                style={{ color: 'var(--text-dark)' }}>
                                {TEXTS.LOGIN.TITLE}
                            </h2>
                            {error && (
                                <div className="alert alert-danger border-0 bg-danger bg-opacity-10 text-danger">
                                    {error}
                                </div>
                            )}
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4">
                                    <label className="form-label text-secondary">
                                        {TEXTS.LOGIN.USERNAME}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control form-control-lg border-0 bg-light"
                                        style={{ boxShadow: 'none' }}
                                        value={credentials.username}
                                        onChange={(e) => setCredentials({...credentials, username: e.target.value})}
                                        disabled={isLoading}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="form-label text-secondary">
                                        {TEXTS.LOGIN.PASSWORD}
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control form-control-lg border-0 bg-light"
                                        style={{ boxShadow: 'none' }}
                                        value={credentials.password}
                                        onChange={(e) => setCredentials({...credentials, password: e.target.value})}
                                        disabled={isLoading}
                                    />
                                </div>
                                <button 
                                    type="submit" 
                                    className="btn btn-lg w-100"
                                    style={{
                                        backgroundColor: 'var(--primary-color)',
                                        color: 'white',
                                        border: 'none',
                                    }}
                                    onMouseOver={e => e.target.style.backgroundColor = 'var(--primary-hover)'}
                                    onMouseOut={e => e.target.style.backgroundColor = 'var(--primary-color)'}
                                    disabled={isLoading}
                                >
                                    {isLoading ? TEXTS.LOGIN.LOADING : TEXTS.LOGIN.SUBMIT}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;