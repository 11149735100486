import React from 'react';
import { FaTimes, FaPen } from "react-icons/fa";
import DataTable from './common/DataTable';
import DOMPurify from 'dompurify';

const TextTable = ({ 
    texts, 
    totalItems,
    currentPage,
    totalPages,
    onEdit, 
    onDelete,
    onPageChange,
    pageSize = 10
}) => {
    const columns = [
        { field: 'key', header: 'Ключ', sortable: true, width: '25%' },
        { 
            field: 'content', 
            header: 'Содержание',
            sortable: false,
            width: '60%',
            render: (item) => (
                <div className="text-preview">
                    <div dangerouslySetInnerHTML={{ 
                        __html: DOMPurify.sanitize(
                            (item.content.length > 200 
                                ? item.content.substring(0, 200) + '...' 
                                : item.content
                            ).replace(/\n/g, '<br>')
                        )
                    }} />
                </div>
            )
        },
        {
            field: 'actions',
            header: 'Действия',
            sortable: false,
            width: '15%',
            render: (item) => (
                <div className="action-btns">
                    <button
                        onClick={() => onEdit(item)}
                        className="btn btn-warning btn-sm"
                    >
                        <FaPen size={16} />
                    </button>
                    <button
                        onClick={() => onDelete(item.id)}
                        className="btn btn-danger btn-sm"
                    >
                        <FaTimes size={16} />
                    </button>
                </div>
            )
        }
    ];

    return (
        <div className="col-12">
            <DataTable
                data={texts}
                columns={columns}
                totalItems={totalItems}
                currentPage={currentPage}
                totalPages={totalPages}
                pageSize={pageSize}
                onPageChange={onPageChange}
                title="Тексты"
            />
        </div>
    );
};

export default TextTable;