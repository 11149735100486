
import React, { useState, useEffect, useCallback } from "react";
import SubscriptionPlanForm from './SubscriptionPlanForm';
import SubscriptionPlanTable from './SubscriptionPlanTable';
import { subscriptionPlanService } from '../config/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SubscriptionPlansComponent = () => {
    const [plansData, setPlansData] = useState({
        items: [],
        total: 0,
        page: 1,
        pages: 1,
        per_page: 10
    });
    const [editItem, setEditItem] = useState(null);
    const [formData, setFormData] = useState({
        name: null,
        price: null,
        duration_days: null,
        type: null
    });

    const handlePageChange = useCallback(async (page) => {
        try {
            const response = await subscriptionPlanService.getPlans(page, plansData.per_page);
            if (!response.ok) throw new Error('Failed to fetch subscription plans');
            const data = await response.json();
            setPlansData(prevData => ({
                ...prevData,
                items: data.items,
                page: page,
                total: data.total,
                pages: data.pages
            }));
        } catch (error) {
            toast.error('Error fetching subscription plans');
        }
    }, [plansData.per_page]);

    useEffect(() => {
        handlePageChange(1);
    }, [handlePageChange]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = editItem
                ? await subscriptionPlanService.updatePlan(editItem.id, formData)
                : await subscriptionPlanService.createPlan(formData);

            if (!response.ok) throw new Error('Failed to save subscription plan');

            const savedPlan = await response.json();
            
            if (editItem) {
                setPlansData(prevData => ({
                    ...prevData,
                    items: prevData.items.map(plan => 
                        plan.id === editItem.id ? savedPlan : plan
                    )
                }));
                toast.success('План подписки успешно обновлен');
            } else {
                handlePageChange(1);
                toast.success('План подписки успешно добавлен');
            }

            setFormData({
                name: null,
                price: null,
                duration_days: null,
                type: null
            });
            setEditItem(null);
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleEdit = (item) => {
        setFormData({
            name: item.name,
            price: item.price,
            duration_days: item.duration_days,
            type: item.type
        });
        setEditItem(item);
    };

    const handleDelete = async (id) => {
        try {
            const response = await subscriptionPlanService.deletePlan(id);
            if (!response.ok) throw new Error('Failed to delete subscription plan');

            setPlansData(prevData => ({
                ...prevData,
                items: prevData.items.filter(plan => plan.id !== id),
                total: prevData.total - 1
            }));
            toast.success('План подписки успешно удален');
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: value
        }));
    };

    return (
        <div className="row g-4">
            <SubscriptionPlanForm
                formData={formData}
                editItem={editItem}
                onInputChange={handleInputChange}
                onSubmit={handleSubmit}
                onClear={() => {
                    setFormData({
                        name: null,
                        price: null,
                        duration_days: null,
                        type: null
                    });
                    setEditItem(null);
                }}
            />
            <SubscriptionPlanTable
                plans={plansData.items}
                totalItems={plansData.total}
                currentPage={plansData.page}
                totalPages={plansData.pages}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onPageChange={handlePageChange}
                pageSize={plansData.per_page}
            />
            <ToastContainer />
        </div>
    );
};

export default SubscriptionPlansComponent;