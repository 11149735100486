import React, { useState } from 'react';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import "./DataTable.css";

const DataTable = ({
    data,
    columns,
    totalItems,
    currentPage,
    totalPages,
    pageSize,
    onPageChange,
    title
}) => {
    const [sortConfig, setSortConfig] = useState({ field: null, direction: null });

    const handleSort = (field) => {
        let direction = 'asc';
        if (sortConfig.field === field) {
            direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        }
        setSortConfig({ field, direction });
    };

    const getSortIcon = (field) => {
        if (sortConfig.field !== field) return <FaSort />;
        return sortConfig.direction === 'asc' ? <FaSortUp /> : <FaSortDown />;
    };

    const sortedData = React.useMemo(() => {
        if (!sortConfig.field) return data;

        return [...data].sort((a, b) => {
            const aValue = a[sortConfig.field];
            const bValue = b[sortConfig.field];

            if (aValue === null || aValue === undefined) return 1;
            if (bValue === null || bValue === undefined) return -1;

            if (typeof aValue === 'string') {
                return sortConfig.direction === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            }

            return sortConfig.direction === 'asc'
                ? aValue - bValue
                : bValue - aValue;
        });
    }, [data, sortConfig]);

    return (
        <div className="card">
            <div className="card-body">
                {title && (
                    <h5 className="card-title mb-4">
                        {title} <span className="text-muted">({totalItems})</span>
                    </h5>
                )}
                <div className="table-wrapper">
                    <table className="table">
                        <thead>
                            <tr>
                                {columns.map((column, index) => (
                                    <th
                                        key={index}
                                        onClick={() => column.sortable !== false && handleSort(column.field)}
                                        style={{ cursor: column.sortable !== false ? 'pointer' : 'default' }}
                                    >
                                        <div className="d-flex align-items-center gap-2">
                                            {column.header}
                                            {column.sortable !== false && getSortIcon(column.field)}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedData.map((item, rowIndex) => (
                                <tr key={item.id || rowIndex}>
                                    {columns.map((column, colIndex) => (
                                        <td key={colIndex}>
                                            {column.render ? column.render(item) : item[column.field]}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {totalPages > 1 && (
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <small className="text-muted">
                            Страница {currentPage} из {totalPages}
                        </small>
                        <nav>
                            <ul className="pagination mb-0">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => onPageChange(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    >
                                        Предыдущая
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
                                    <li
                                        key={page}
                                        className={`page-item ${page === currentPage ? 'active' : ''}`}
                                    >
                                        <button
                                            className="page-link"
                                            onClick={() => onPageChange(page)}
                                        >
                                            {page}
                                        </button>
                                    </li>
                                ))}
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => onPageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages}
                                    >
                                        Следующая
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DataTable;
