import { API_HOST } from '../config/api';

export const authService = {
    login: (username, password) =>
        fetch(`${API_HOST}/api/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password }),
        }).then(res => {
            if (!res.ok) throw new Error('Auth failed');
            return res.json();
        }),

    logout: () => {
        localStorage.removeItem('token');
    },

    getToken: () => localStorage.getItem('token'),

    setToken: (token) => localStorage.setItem('token', token),
};