import React, { useState, useEffect, useCallback } from "react";
import ChannelForm from './ChannelForm';
import ChannelTable from './ChannelTable';
import { channelService } from '../config/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChannelsComponent = () => {
    const [channelsData, setChannelsData] = useState({
        items: [],
        total: 0,
        page: 1,
        pages: 1,
        per_page: 10
    });
    const [editItem, setEditItem] = useState(null);
    const [formData, setFormData] = useState({
        telegram_id: "",
        name: "",
        url: "",
    });

    const handlePageChange = useCallback(async (page) => {
        try {
            const response = await channelService.getChannels(page, channelsData.per_page);
            if (!response.ok) throw new Error('Failed to fetch channels');
            const data = await response.json();
            setChannelsData(prevData => ({
                ...prevData,
                items: data.items,
                page: page,
                total: data.total,
                pages: data.pages
            }));
        } catch (error) {
            toast.error('Error fetching channels');
        }
    }, [channelsData.per_page]);

    // Fetch channels data
    useEffect(() => {
        handlePageChange(1);
    }, [handlePageChange]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = editItem
                ? await channelService.updateChannel(editItem.id, formData)
                : await channelService.createChannel(formData);

            if (!response.ok) throw new Error('Не удалось сохранить канал');

            const savedChannel = await response.json();

            if (editItem) {
                setChannelsData(prevData => ({
                    ...prevData,
                    items: prevData.items.map(channel =>
                        channel.id === editItem.id ? savedChannel : channel
                    )
                }));
                toast.success('Канал успешно обновлен');
            } else {
                setChannelsData(prevData => ({
                    ...prevData,
                    items: [...prevData.items, savedChannel],
                    total: prevData.total + 1
                }));
                toast.success('Канал успешно добавлен');
            }

            // Reset form
            setFormData({
                telegram_id: "",
                name: "",
                url: "",
                is_active: true
            });
            setEditItem(null);

        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleEdit = (item) => {
        setFormData({
            telegram_id: item.telegram_id,
            name: item.name,
            url: item.url,
            is_active: item.is_active
        });
        setEditItem(item);
    };

    const handleDelete = async (id) => {
        try {
            const response = await channelService.deleteChannel(id);
            if (!response.ok) throw new Error('Не удалось удалить канал');

            setChannelsData(prevData => ({
                ...prevData,
                items: prevData.items.filter(channel => channel.id !== id),
                total: prevData.total - 1
            }));
            toast.success('Канал успешно удален');
        } catch (error) {
            toast.error(`Error: ${error.message}`);
        }
    };

    const handleInputChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [id]: type === "checkbox" ? checked : value
        }));
    };

    return (
        <div className="row g-4">
            <ChannelForm
                formData={formData}
                editItem={editItem}
                onInputChange={handleInputChange}
                onSubmit={handleSubmit}
                onClear={() => {
                    setFormData({
                        telegram_id: "",
                        name: "",
                        url: "",
                        is_active: true
                    });
                    setEditItem(null);
                }}
            />
            <ChannelTable
                channels={channelsData.items}
                totalItems={channelsData.total}
                currentPage={channelsData.page}
                totalPages={channelsData.pages}
                onEdit={handleEdit}
                onDelete={handleDelete}
                onPageChange={handlePageChange}
                pageSize={channelsData.per_page}
            />
            <ToastContainer />
        </div>
    );
};

export default ChannelsComponent;
