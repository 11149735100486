import React, { useState } from 'react';
import DOMPurify from 'dompurify';

const TextForm = ({ formData, editItem, onInputChange, onSubmit, onClear }) => {
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        const newErrors = {};
        if (!formData.key) newErrors.key = 'Необходимо указать ключ';
        if (!formData.content) newErrors.content = 'Необходимо указать содержание';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const validateHtml = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;

        // Check for unclosed tags
        const openTags = [];
        const allowedTags = ['b', 'i', 'u', 's', 'a', 'code', 'pre'];

        // Parse HTML and check tags
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Check for parsing errors
        const parseError = doc.querySelector('parsererror');
        if (parseError) {
            return false;
        }

        // Validate all tags are properly closed and allowed
        const tags = html.match(/<[^>]+>/g) || [];
        for (const tag of tags) {
            if (tag.startsWith('</')) {
                const tagName = tag.slice(2, -1);
                if (openTags[openTags.length - 1] !== tagName) {
                    return false;
                }
                openTags.pop();
            } else {
                const tagName = tag.slice(1).split(/[\s>]/)[0];
                if (!allowedTags.includes(tagName)) {
                    return false;
                }
                if (!tag.endsWith('/>')) {
                    openTags.push(tagName);
                }
            }
        }

        return openTags.length === 0;
    };

    const applyFormatting = (tag) => {
        const textarea = document.getElementById('content');
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;
        const selectedText = formData.content.substring(start, end);

        let newText;
        if (tag === 'a') {
            const url = prompt('Введите URL:');
            if (!url) return;
            newText = formData.content.substring(0, start) +
                `<a href="${url}">${selectedText}</a>` +
                formData.content.substring(end);
        } else {
            newText = formData.content.substring(0, start) +
                `<${tag}>${selectedText}</${tag}>` +
                formData.content.substring(end);
        }

        onInputChange({
            target: {
                id: 'content',
                value: newText
            }
        });
    };

    const handleContentChange = (e) => {
        const newContent = e.target.value;
        if (!validateHtml(newContent)) {
            setErrors(prev => ({
                ...prev,
                content: 'Неверный HTML формат или незакрытые теги'
            }));
        } else {
            setErrors(prev => ({
                ...prev,
                content: null
            }));
        }
        onInputChange(e);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        onSubmit(e);
    };

    const renderPreview = () => {
        const sanitizedHtml = DOMPurify.sanitize(formData.content);
        // Заменяем \n на <br> для корректного отображения переносов строк
        const htmlWithLineBreaks = sanitizedHtml.replace(/\n/g, '<br>');
        return <div dangerouslySetInnerHTML={{ __html: htmlWithLineBreaks }} />;
    };

    return (
        <div className="col-12">
            <div className="card mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <form onSubmit={handleSubmit}>
                                <div className="alert alert-info mb-4" role="alert">
                                    <h6 className="alert-heading">Поддерживаемые HTML теги и форматирование:</h6>
                                    <ul className="mb-0">
                                        <li><code>&lt;b&gt;</code> - жирный текст</li>
                                        <li><code>&lt;i&gt;</code> - курсив</li>
                                        <li><code>&lt;u&gt;</code> - подчеркнутый текст</li>
                                        <li><code>&lt;s&gt;</code> - зачеркнутый текст</li>
                                        <li><code>&lt;a href="..."&gt;</code> - ссылка</li>
                                        <li><code>&lt;code&gt;</code> - моноширинный текст</li>
                                        <li><code>&lt;pre&gt;</code> - предварительно отформатированный текст</li>
                                        {/* <li><code>\n</code> - перенос строки</li> */}
                                    </ul>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Ключ <span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className={`form-control ${errors.key ? 'is-invalid' : ''}`}
                                        id="key"
                                        value={formData.key}
                                        onChange={onInputChange}
                                        disabled={editItem !== null}
                                    />
                                    {errors.key && <div className="invalid-feedback">{errors.key}</div>}
                                </div>

                                <div className="btn-group mb-2" role="group">
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => applyFormatting('b')} title="Жирный">B</button>
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => applyFormatting('i')} title="Курсив">I</button>
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => applyFormatting('u')} title="Подчёркнутый">U</button>
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => applyFormatting('s')} title="Зачёркнутый">S</button>
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => applyFormatting('code')} title="Код">Code</button>
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => applyFormatting('pre')} title="Форматированный">Pre</button>
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => applyFormatting('a')} title="Ссылка">Link</button>
                                </div>

                                <div className="mb-3">
                                    <label className="form-label">Содержание <span className="text-danger">*</span></label>
                                    <textarea
                                        className={`form-control ${errors.content ? 'is-invalid' : ''}`}
                                        id="content"
                                        value={formData.content}
                                        onChange={handleContentChange}
                                        rows="10"
                                    />
                                    {errors.content && <div className="invalid-feedback">{errors.content}</div>}
                                </div>

                                <div className="d-flex gap-2">
                                    <button type="submit" className="btn btn-primary">
                                        {editItem ? 'Сохранить изменения' : 'Добавить текст'}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => {
                                            onClear();
                                            setErrors({});
                                        }}
                                    >
                                        {editItem ? 'Отменить' : 'Очистить'}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6">
                            <div className="alert alert-warning mb-3">
                                <i className="fas fa-clock me-2"></i>
                                Обновление текстов в боте происходит автоматически в течение 5 минут после сохранения
                            </div>
                            <div className="card" style={{ height: 'calc(100% - 90px)' }}> {/* Вычитаем высоту алерта и отступы */}
                                <div className="card-body d-flex flex-column">
                                    <h6 className="card-subtitle mb-2 text-muted">Предпросмотр в Telegram:</h6>
                                    <div className="border rounded p-3 bg-light flex-grow-1 overflow-auto" style={{ maxHeight: 'calc(100vh - 400px)' }}>
                                        {renderPreview()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextForm;